.chat-container {
    position: static !important;
    bottom: 70px !important;
    right: 20px !important;
    width: 500px;
    height: 500px;
    background: #F9F3FF;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-self: auto !important ;
    justify-self: auto !important;
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }



  .chat-button {
    background-color: #1f2937;
    color: #f3e7ff;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 30px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: block;
    width: 100%;
    transition: transform 0.3s ease;
    position: static;
  }
  .chat-button:hover {
    transform: scale(1.1);
  }
  .show-chat {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  
  .hide-chat {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
  }
  .chat-header {
    background: #1f2937;
    color: #F3E8FF;
    height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    display: "flex";
    padding: "10px";
  }
  .close-btn {
    position: absolute;
    right: 12px;
    top: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  .chatbox {
    height: 600px;
    width: 500px; 
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
  }
  

  .user-message, .bot-message {
    padding: 12px 16px;
    border-radius: 18px;
    max-width: 85%;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .user-message {
    background-color: #111827; 
    color: #F3E8FF;
    align-self: flex-end;
    text-align: right;
  }
  
  .bot-message {
    background-color: #F3E8FF; 
    color: #1f2937;
    align-self: flex-start;
  }
  
  /* input & buttons */
  .chat-input {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #E2E8F0;
    border-radius: 8px;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 12px;
    border: none;
    border-radius: 6px;
    outline: none;
    font-size: 16px;
    background-color: #E2E8F0;
  }
  
  .chat-input button {
    padding: 12px 18px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  /* Buttons Styling */
  .chat-input button.send {
    background-color:#F3E8FF ; 
    color: #111827;
  }
  
  .chat-input button.clear {
    background-color:#64748b ; 
    color: #F3E8FF;
  }
  
  .chat-input button:hover {
    opacity: 0.8;
  }
  
  .bot-typing {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }
  
  .typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 30px;
    background: #e0e0e0;
    border-radius: 15px;
    padding: 5px;
  }
  
  .typing-indicator span {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background: #555;
    border-radius: 50%;
    display: inline-block;
    animation: typing 1.5s infinite ease-in-out;
  }
  
  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes typing {
    0%, 100% {
      opacity: 0.3;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .chatbot-wrapper,
  .chat-button,
  .chat-container {
    position: relative !important;
  }