/*project.css */
.slider-container {
    width: 100%;  /* Ensure the slider has enough width */
    height: auto; /* Adjust to fit content */
    margin: auto; /* Center the slider */
  }
  .flip-card {
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s ease-in-out;
  }
  
  .group:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    border-radius: 0.5rem;
  }
  
  .flip-card-front {
    z-index: 2;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
    z-index: 1;
  }
  